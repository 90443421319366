var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', [_vm.canModify ? _c('b-row', {
    staticClass: "mx-2 my-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('document.searchNameFolder'),
      "value": _vm.folderNameFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    model: {
      value: _vm.folderNameFilter,
      callback: function callback($$v) {
        _vm.folderNameFilter = $$v;
      },
      expression: "folderNameFilter"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mx-50",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.openModalEdit({});
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer mr-25",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('document.createFolder')) + " ")], 1)])], 1)])], 1) : _vm._e()], 1), _c('IAmOverlay', {
    attrs: {
      "loading": !!_vm.loading
    }
  }, [_c('b-table', {
    ref: "refFolderListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchAllFolders,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(data.label ? _vm.$t("document.columns.folders.".concat(data.label)) : '') + " ")])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 p-0",
          attrs: {
            "name": "check-box",
            "inline": "",
            "checked": _vm.isChecked(data.index)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(data.index);
            }
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(data) {
        return [_vm.canModify ? _c('div', [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-info",
            modifiers: {
              "hover": true,
              "v-info": true
            }
          }],
          staticClass: "px-1 mr-50",
          attrs: {
            "variant": "flat-info",
            "title": _vm.isMobileView ? '' : 'Đổi tên thư mục'
          },
          on: {
            "click": function click($event) {
              return _vm.openModalEdit(data.item);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "EditIcon",
            "size": "16"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger",
            modifiers: {
              "hover": true,
              "v-danger": true
            }
          }],
          staticClass: "px-1",
          attrs: {
            "variant": "flat-danger",
            "title": _vm.isMobileView ? '' : 'Xoá thư mục'
          },
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(data.item);
            }
          }
        }, [_c('feather-icon', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1)], 1) : _vm._e()];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "max-width": "500px"
          }
        }, [_c('div', {
          staticClass: "w-100 flex-grow-1"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-info",
          attrs: {
            "to": {
              name: 'apps-documents-list',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])], 1)])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")])];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-1 d-flex-center justify-content-sm-start"
  }, [_c('div', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.total')) + " " + _vm._s(_vm.totalFolders) + " " + _vm._s(_vm.$t('document.folder')) + " ")])])], 1), _c('ModalFolder', {
    attrs: {
      "folder-data": _vm.folderData
    },
    on: {
      "refetch-data": _vm.refetchFolderData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }