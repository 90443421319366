<template>
  <!-- Modal Create Document Folder -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-modal
      id="modal-create-folder"
      centered
      no-close-on-backdrop
      :title="isRename ? $t('document.renameFolder') : $t('document.createFolder')"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('document.folderName')"
              rules="required"
            >
              <b-form-group
                label-for="documentName"
              >
                <template #label>
                  {{ $t('document.folderName') }}
                  <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="folderDataToEdit.name"
                  type="text"
                  :placeholder="$t('document.folderName')"
                  :state="getValidationState(validationContext) === false ? false : null"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="outline-danger"
          pill
          @click="$root.$emit('bv::hide::modal', 'modal-create-folder')"
        >
          {{ $t('cancel') }}
        </b-button>

        <b-button
          class="btn-gradient mt-lg-25"
          pill
          :disabled="loading"
          @click="handleSubmit(submit)"
        >
          <b-spinner
            v-if="loading"
            small
          />
          {{ $t('confirmation') }}
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { getDifference } from '@/@core/utils/utils'

import useDocumentHandle from '@document/useDocumentHandle'

export default {
  name: 'ModalFolder',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  props: {
    folderData: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const folderDataToEdit = ref({})
    const { addDocument, updateDocument } = useDocumentHandle()
    const isRename = computed(() => !isEmpty(props.folderData))
    const resetData = () => {
      folderDataToEdit.value = cloneDeep(props.folderData)
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData)
    const loading = ref(false)

    async function submit() {
      const isUpdate = !!props.folderData.id
      const payload = getDifference(folderDataToEdit.value, props.folderData)
      payload.type = 'FOLDER'
      loading.value = true
      try {
        if (isUpdate) {
          await updateDocument(props.folderData, payload)
        } else {
          await addDocument({
            ...folderDataToEdit.value,
            type: 'FOLDER',
          })
        }
      } finally {
        emit('refetch-data')
        this.$root.$emit('bv::hide::modal', 'modal-create-folder')
        loading.value = false
      }
    }

    watch(() => props.folderData, val => {
      folderDataToEdit.value = cloneDeep(val)
    })

    return {
      folderDataToEdit,
      submit,
      refFormObserver,
      getValidationState,
      resetForm,
      resetData,
      loading,
      isRename,
    }
  },
}
</script>
