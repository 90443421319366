var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-create-folder",
            "centered": "",
            "no-close-on-backdrop": "",
            "title": _vm.isRename ? _vm.$t('document.renameFolder') : _vm.$t('document.createFolder')
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-button', {
                attrs: {
                  "variant": "outline-danger",
                  "pill": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.$root.$emit('bv::hide::modal', 'modal-create-folder');
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('b-button', {
                staticClass: "btn-gradient mt-lg-25",
                attrs: {
                  "pill": "",
                  "disabled": _vm.loading
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm.loading ? _c('b-spinner', {
                attrs: {
                  "small": ""
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('confirmation')) + " ")], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.folderName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "documentName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('document.folderName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "name",
                  "type": "text",
                  "placeholder": _vm.$t('document.folderName'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.folderDataToEdit.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.folderDataToEdit, "name", $$v);
                  },
                  expression: "folderDataToEdit.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }